/*----------------------------------------*/
/*  06. COUNTER CSS START
/*----------------------------------------*/

.counter {
    &__area {
        position: relative;

        &-2 {
            &::before {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 330%;
                z-index: -1;
            }
        }
    }

    &__inner {
        @include border-radius(20px);
        @include box-shadow(0px 40px 80px 0px rgba(43, 2, 89, 0.1));
        padding: 90px 0;
        padding-bottom: 65px;
    }

    &__item {
        & h2 {
            font-size: 50px;
            margin-bottom: 0;
            font-weight: 900;
            line-height: 1;
        }

        & span {
            text-transform: capitalize;
        }

        &-2 {
            & span {
                color: $black-soft-2;
            }
        }
    }
}