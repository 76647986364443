/*----------------------------------------*/
/*  11. CONTACT CSS START
/*----------------------------------------*/

.contact {
    &__area {
        position: relative;

        & .contact__form {
            margin-bottom: -71px;
        }
    }

    &__wrapper {
        background: $white;
        padding: 40px;
        @include box-shadow(0px 10px 30px 0px rgba(3, 0, 53, 0.1));
        @include border-radius(10px);
    }

    &__info {
        & h3 {
            font-size: 30px;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 25px;

            @media #{$xs} {
                font-size: 30px;
            }
        }
    }

    &__form {
        margin-bottom: -90px;

        &.home {
            margin-bottom: -100px;

            @media #{$xs} {
                margin-bottom: -90px;
            }
        }

        & input,
        & textarea {
            height: 50px;
            line-height: 46px;
            color: $black;
            width: 100%;
            background: $grey-9;
            border: 2px solid transparent;
            @include border-radius(10px);
            margin-bottom: 15px;
            padding: 0 15px;

            &::placeholder {
                font-size: 13px;
                text-transform: capitalize;
                color: $body-text-color;
            }

            &:focus {
                border-color: $theme-color;
                background: $white;
                outline: none;

                &::placeholder {
                    opacity: 0;
                }
            }
        }

        & textarea {
            height: 125px;
            resize: none;
        }
    }

    &__home {
        background: $white;
        padding: 40px;
        @include box-shadow(0px 10px 30px 0px rgba(3, 0, 53, 0.1));
        @include border-radius(10px);

        @media #{$laptop} {
            padding: 30px;
        }

        @media #{$xs} {
            padding: 30px;
            margin-bottom: 50px;
        }
    }
}