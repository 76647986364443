/*----------------------------------------*/
/*  12. FAQ CSS START
/*----------------------------------------*/

.faq {
    &__wrapper {
        & .card {
            margin-bottom: 20px;
            border: none;
            background: transparent;
        }

        & .card-header {
            padding: 0;
            border: 0;
            border: none;
            background: $grey-12;
        }

        & .btn-link {
            position: relative;
            padding: 15px 23px;
            width: 100%;
            text-align: left;
            font-size: 18px;
            color: $black;
            font-weight: 700;
            text-decoration: none;
            border: 2px solid $white;
            background: $white;
            @include border-radius(10px);
            @include box-shadow(0px 30px 40px 0px rgba(2, 0, 40, 0.1));

            &::before {
                position: absolute;
                content: '\f068';
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                top: 50%;
                right: 25px;
                @include transform(translateY(-50%));
                font-size: 18px;
                color: $black;
                color: $theme-color;
            }

            &.collapsed {
                @include box-shadow(none);
                background: $grey-12;
                border-color: $border-8;

                &::before {
                    @include transform(translateY(-50%) rotate(180deg));
                    color: $black;
                    content: '\f067';
                    font-weight: 300;
                    font-family: "Font Awesome 5 Pro";
                }
            }

            &:hover {
                text-decoration: none;
            }
        }

        & .card-body {
            padding: 30px;
            line-height: 30px;
            padding-right: 60px;
        }
    }

    &__thumb {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        @include background();

        @media #{$lg} {
            width: 48%;
        }

        @media #{$md} {
            width: 40%;
        }

        @media #{$md} {
            display: none;
        }

        @media #{$sm} {
            display: none;
        }

        @media #{$xs} {
            display: none;
        }
    }

    &__accordion {
        @media #{$sm} {
            margin-bottom: 50px;
        }

        @media #{$xs} {
            margin-bottom: 50px;
        }

        & .card {
            margin-bottom: 20px;
            border: none;
            background: $white;
            @include box-shadow(0px 10px 30px 0px rgba(3, 0, 53, 0.1));
            @include border-radius(10px);

            &:last-of-type {
                margin-bottom: 0px;
            }
        }

        & .card-header {
            padding: 0;
            border: 0;
            border: none;
            background: $white;
            @include border-radius(10px);
        }

        & .btn-link {
            position: relative;
            padding: 15px 20px;
            padding-right: 50px;
            width: 100%;
            text-align: left;
            font-size: 18px;
            color: $theme-color;
            font-weight: 500;
            text-decoration: none;
            border: none;
            background: $white;
            @include border-radius(10px);

            &::before {
                position: absolute;
                content: '';
                background: url(../img/minus-icon.svg) center/1em auto no-repeat;
                top: 50%;
                right: 25px;
                @include transform(translateY(-50%));
                font-size: 22px;
                width: 22px;
                height: 22px;
                filter: invert(25%) sepia(92%) saturate(7401%) hue-rotate(248deg) brightness(106%) contrast(101%);
            }

            &.collapsed {
                background: $white;
                color: $black;

                &::before {
                    background: url(../img/plus-icon.svg) center/1em auto no-repeat;
                    filter: none;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }

        & .card-body {
            padding: 30px;
            line-height: 26px;
            padding-right: 70px;
            padding-top: 0;
        }
    }
}

.faq__accordion .btn:focus {
    box-shadow: none;
}

.faq__accordion .card-body p {
    line-height: 30px;
    margin-bottom: 0px;
}

.faq__accordion .card-body ul {
    & li {
        margin-top: 10px;
        padding-left: 25px;

        & span {
            position: relative;

            &::after {
                position: absolute;
                content: '';
                left: -22px;
                top: 7px;
                width: 7px;
                height: 7px;
                background: $black-soft;
                @include border-radius(50%);
            }
        }
    }
}