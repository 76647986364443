/*----------------------------------------*/
/*  09. PRICE CSS START
/*----------------------------------------*/

.price {
    &__area {
        scroll-margin-top: 80px;

        &.sticky {
            position: sticky;
            top: 79px;
            z-index: 99;
            background: $theme-color;

            @media #{$xs} {
                top: 85px;
            }
        }

        & .scrollLinks {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0px;

            @media #{$xs} {
                flex-wrap: wrap;
            }

            & button {
                background: transparent;
                color: $white;
                font-weight: 600;
                font-size: 18px;
                margin: 0px 30px;

                @media #{$xs} {
                    margin: 0px;
                    margin-bottom: 8px;
                    padding-bottom: 8px;
                    width: 100%;
                    border-bottom: 1px solid $blue-2;
                    flex: 1 0 50%;
                }
            }
        }

        &.fixBox {
            scroll-margin-top: 100px;

            @media #{$xs} {
                scroll-margin-top: 150px;
            }
        }
    }

    &__inner {
        padding: 30px;
    }

    &__item {
        @include border-radius(10px);
        box-shadow: 0px 10px 20px 0px rgba(2, 0, 40, 0.1);

        &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: -moz-linear-gradient(-120deg, rgb(105, 14, 208) 0%, rgb(23, 13, 162) 100%);
            background-image: -webkit-linear-gradient(-120deg, rgb(105, 14, 208) 0%, rgb(23, 13, 162) 100%);
            background-image: -ms-linear-gradient(-120deg, rgb(105, 14, 208) 0%, rgb(23, 13, 162) 100%);
            background-image: linear-gradient(-120deg, rgb(105, 14, 208) 0%, rgb(23, 13, 162) 100%);
            z-index: -1;
            opacity: 0;
            visibility: hidden;
        }

        & img {
            box-shadow: 0px 5px 25px 0px rgba(2, 0, 40, 0.3);
        }

        & p {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
            color: $black;
        }

        & a.price-btn {
            display: block;
            min-height: 50px;
            line-height: 50px;
            text-align: center;
            color: $theme-color;
            background: $grey-4;
            padding: 0 80px;
            min-width: 250px;
            font-size: 15px;
            font-weight: 700;
            @include border-radius(10px);

            &:hover {
                background: $white;
                color: $theme-color;
            }
        }

        &:hover,
        &.active {
            &::after {
                visibility: visible;
                opacity: 1;
            }

            & .price-btn {
                background: $white;
                color: $theme-color;
            }

            & p {
                color: $white;
            }

            & span {
                color: $white;
            }

            & .price__tag {
                & h1 {
                    color: $white;
                }

                & span {
                    color: $white;
                }
            }

            & .price__features {
                & b {
                    color: $white;
                }

                & ul {
                    & li {
                        & span {
                            color: $white;

                            &::after {
                                background: $white;
                            }

                            & a {
                                color: #fec53b;

                                &:hover {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            & .price__shape {
                right: -150px;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__shape {
        top: -75px;
        right: -100%;
        visibility: hidden;
        opacity: 0;
    }

    &__tag {
        & h1 {
            font-size: 22px;
            font-weight: 500;
            margin: 0px 6px;
            line-height: 1;
            display: flex;
            align-items: center;

            & i {
                font-size: 18px;
            }

            @media #{$xs} {
                margin: 0px 6px 0px 0px;
            }
        }

        span.start {
            @media #{$xs} {
                flex: 1 0 100%;
                margin-bottom: 5px;
            }
        }
    }

    &__features {
        & b {
            color: $black;
        }

        & ul {
            & li {
                margin-bottom: 10px;
                padding-left: 22px;

                & span {
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        left: -22px;
                        top: 7px;
                        width: 7px;
                        height: 7px;
                        background: $theme-color;
                        @include border-radius(50%);
                    }
                }
            }
        }
    }

    &__doc {
        & b {
            color: $black;
        }

        & ul {
            & li {
                display: inline-block;
                margin-right: 25px;
                color: $theme-color;

                &:last-child {
                    margin: 0px;
                }

                & i {
                    font-size: 18px;
                }

                @media #{$xs} {
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    &__tab {
        position: relative;

        & .nav-tabs {
            position: relative;
            width: 220px;
            @include border-radius(6px);
            background: $grey-8;
            padding: 5px;
            border-bottom: 0;

            & .nav-item {
                margin-right: 14px;

                &:last-child {
                    margin-right: 0;
                }

                & .nav-link {
                    padding: 0 25px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    background: transparent;
                    border: 0;
                    @include border-radius(6px);
                    font-weight: 700;

                    &.active {
                        background: $white;
                        color: $theme-color;
                        @include box-shadow(0px 2px 6px 0px rgba(11, 0, 65, 0.06));
                    }
                }
            }
        }
    }

    &__offer {
        position: absolute;
        top: -50px;
        right: 28%;

        & span {
            display: inline-block;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            padding: 0 15px;
            color: $green;
            background: $green-3;
            font-weight: 700;
            @include border-radius(12px);
        }

        & img {
            position: absolute;
            top: 0;
            right: 98%;
        }
    }
}

.price__tab button:hover {
    color: inherit;
}